import './styles.scss';

import { Col, Form, Input, Row, Typography } from 'antd';
import clsx from 'clsx';
import { NotificationManager } from 'components/Notification';
import ShoppingCartType from 'components/ShoppingCartType';
import useAsync from 'hooks/useAsync';
import usePermission from 'hooks/usePermission';
import { ASYNC_STATUS, USER_FORM_TYPES } from 'libs/constants';
import { MODULE_USER_MANAGEMENT_RIGHTS } from 'libs/constants/modulerights';
import { isValidArray } from 'libs/utils/array';
import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { adminFapiUserManagement, fapiRoleService } from 'services/adminFapi';
import { selectAssignableCountryRolesList } from 'store/selectors/roleSelector';
import { customerActions } from 'store/slices/customerSlice';

import { DEFAULT_LANGUAGES } from '../../libs/constants/lang';
import {
  USER_ACCOUNT_STATUS,
  USER_SALUTATION_ARRAY,
} from '../../libs/constants/user';
import UserStatusCheckbox from '../../pages/Admin/InviteNewUserPage/components/UserStatusCheckbox';
import { selectUserId } from '../../store/selectors/authenticationSelectors';
import {
  selectAvailableLanguages,
  selectIsFetchingUserConfigurations,
  selectUserCountryId,
} from '../../store/selectors/userSelector';
import B2becAssignRole from '../B2becAssignRole';
import B2becCustomSelect from '../B2becCustomSelect';
import B2becTranslation from '../B2becTranslation';
import CustomButton from '../CustomButton';
import B2bSelectSalesOrg from './B2bSelectSalesOrg';

const { useForm } = Form;

export const CustomFormItem = ({
  className,
  name,
  children,
  label,
  displayLabel,
  isSharedLabel,
  ...restProps
}) => {
  const { t } = useTranslation();

  const formLabel = displayLabel
    ? isSharedLabel
      ? t(`form.label.${label || name}`)
      : t(`${label || name}`)
    : '';

  return (
    <Form.Item
      name={name}
      className={clsx('custom-form-item', className)}
      label={formLabel}
      {...restProps}
    >
      {React.cloneElement(children, {
        placeholder: t(`form.placeHolder.${label || name}`),
      })}
    </Form.Item>
  );
};

CustomFormItem.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  displayLabel: PropTypes.bool,
  isSharedLabel: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

CustomFormItem.defaultProps = {
  className: '',
  label: '',
  displayLabel: true,
  isSharedLabel: true,
};

const B2bUserForm = ({
  form: propForm,
  initialValues,
  submitFunc,
  submitText,
  changeEmailAddress,
  changeCustomerSap,
  doAssignRoles,
  cancelText,
  errorMessageKey,
  successMessageKey,
  onSuccess,
  onError,
  onCancel,
  type,
  userId,
  assignedRoles,
  isInvitation,
  ...props
}) => {
  const { language } = initialValues || {};

  const { t } = useTranslation();

  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [isError, setIsError] = useState(false);
  const [responseErrorMessages, setResponseErrorMessages] = useState(null);
  const [form] = useForm(propForm);

  const { execute: handleSubmit, status } = useAsync(submitFunc, false);
  const {
    execute: executeChangeEmailAddress,
    status: changeEmailAddressStatus,
  } = useAsync(changeEmailAddress, false);

  const { execute: executeHandleAssignRoles, status: handleAssignRolesStatus } =
    useAsync(doAssignRoles, false);

  const { execute: executeChangeCustomerSap, status: changeCustomerSapStatus } =
    useAsync(changeCustomerSap, false);

  const isEditForm = type === USER_FORM_TYPES.EDIT;

  const isInvitationForm = type === USER_FORM_TYPES.INVITE;

  const currentUserId = useSelector(selectUserId);
  const isFetchingUserConfigurations = useSelector(
    selectIsFetchingUserConfigurations
  );
  const countryId = useSelector(selectUserCountryId);
  const availableLanguages = useSelector(selectAvailableLanguages);
  const assignableCountryRolesList = useSelector(
    selectAssignableCountryRolesList
  );
  const { hasPermission: canUpdateUser } = usePermission(
    MODULE_USER_MANAGEMENT_RIGHTS.UPDATE_USER
  );

  const shouldShowAssignRolesSection =
    !isInvitationForm &&
    canUpdateUser &&
    isValidArray(assignableCountryRolesList);

  const customInitialValues = useMemo(() => {
    if (isEditForm) {
      if (!language || language === 'N/A') {
        return { ...initialValues, language: '', countryId };
      }
      const matchedLang = availableLanguages.find(
        (item) => item?.value === language
      );
      if (!matchedLang?.label) {
        return { ...initialValues, language: '', countryId };
      }
      return { ...initialValues, language: matchedLang.value, countryId };
    }
    if (isInvitationForm) {
      return {
        userStatus: USER_ACCOUNT_STATUS.AUTHORIZED,
        countryId,
      };
    }
    return undefined;
  }, [
    availableLanguages,
    countryId,
    initialValues,
    isEditForm,
    isInvitationForm,
    language,
  ]);

  const handleError = (error) => {
    if (isMounted.current) {
      setIsError(true);
    }

    if (onError) {
      onError(error, form);
    }
  };

  const dispatch = useDispatch();

  const handleInvitationError = useCallback((error) => {
    if (error) {
      setIsError(true);
      NotificationManager.error({
        message: 'notification.error.submit',
        description: error?.response?.data,
      });
    }
  }, []);

  const handleSuccess = (requestBody) => {
    if (onSuccess) {
      onSuccess(requestBody, form);
    }
  };

  const handleChangeEmailAddress = (email, requestBody) => {
    const changeEmailRequestBody = {
      email,
      countryId: requestBody.country,
      language: requestBody.language,
    };
    return executeChangeEmailAddress(changeEmailRequestBody);
  };

  const handleAssignRoles = (roles) => {
    return executeHandleAssignRoles({ userId, roles });
  };

  const handleChangeCustomerSap = (email, sapCustomerNumber, requestBody) => {
    const changeSapNumberRequestBody = {
      countryId: requestBody.country,
      sapCustomerId: sapCustomerNumber,
      email:
        email !== initialValues?.contactEmail
          ? email
          : initialValues?.contactEmail,
    };
    return executeChangeCustomerSap(changeSapNumberRequestBody);
  };

  const onSubmit = async ({
    contactEmail: email,
    contactNumber: phoneNumber,
    sapCustomerNumber,
    userStatus,
    salutation: title,
    countryRoles,
    ...values
  }) => {
    const requestBody = {
      email,
      phoneNumber,
      title,
      status: userStatus,
      sapCustomerNumber,
      ...values,
    };

    if (isInvitation) {
      try {
        await handleSubmit(requestBody);

        NotificationManager.success({ message: successMessageKey });
        handleSuccess(requestBody);
      } catch (error) {
        console.error('error when updating user', error);
      }
    } else {
      const promises = [];

      setResponseErrorMessages(null);
      setIsError(false);

      if (isEditForm) {
        if (!requestBody.language) {
          if (language && language !== 'N/A') {
            requestBody.language = language;
          } else {
            requestBody.language = DEFAULT_LANGUAGES[0].value;
          }
        }

        if (
          JSON.stringify(countryRoles) !==
          JSON.stringify(initialValues?.countryRoles)
        ) {
          const formatCountryRoles = countryRoles.reduce((result, item) => {
            // eslint-disable-next-line no-unused-expressions
            item?.roles?.forEach((roleItem) =>
              result.push({
                country: item.country,
                role: roleItem,
              })
            );
            return result;
          }, []);

          const doAssignRolesPromise = handleAssignRoles(formatCountryRoles);
          promises.push(doAssignRolesPromise);
        }

        if (email !== initialValues?.contactEmail) {
          const changeEmailAddressPromise = handleChangeEmailAddress(
            email,
            requestBody
          );
          promises.push(changeEmailAddressPromise);
        }

        if (sapCustomerNumber !== initialValues?.sapCustomerNumber) {
          const changeCustomerSapPromise = handleChangeCustomerSap(
            email,
            sapCustomerNumber,
            requestBody
          );

          promises.push(changeCustomerSapPromise);
        }
      }

      // Todo: Refactor later
      const submitNormalData = handleSubmit(requestBody).then(({ error }) => {
        if (error) {
          if (isInvitationForm) {
            handleInvitationError(error);
          } else {
            handleError(error);
          }

          return Promise.resolve({ error });
        }

        return Promise.resolve();
      });

      promises.push(submitNormalData);

      const results = await Promise.allSettled(promises);
      const error = results.find((result) => result?.value?.error);

      if (error) {
        const url = error?.value?.error?.config?.url;
        if (url?.includes('changeemail')) {
          NotificationManager.error({
            message: 'notification.error.updateEmailAddress',
          });
        } else if (url?.includes('sapcustomer')) {
          NotificationManager.error({
            message: 'notification.error.updateSapCustomerNumber',
          });
        } else if (url?.includes('assign-many')) {
          NotificationManager.error({
            message: 'notification.error.assignRoles',
          });
        }
        handleError(error);
      } else {
        NotificationManager.success({ message: successMessageKey });

        if (
          currentUserId === userId &&
          sapCustomerNumber !== initialValues?.sapCustomerNumber
        ) {
          dispatch(customerActions.updateCustomerSAP(sapCustomerNumber));
        }

        handleSuccess(requestBody);
      }
    }
  };

  const validateMessages = {
    required: (label = '') => {
      return (
        <B2becTranslation
          value="form.validate.required"
          variables={{ label }}
        />
      );
    },
    types: {
      email: (label = '') => {
        return (
          <B2becTranslation
            value="form.validate.isNotValid"
            variables={{ label }}
          />
        );
      },
    },
  };

  return (
    <>
      {isError ? (
        <Typography.Text
          className="b2bec-change-invoice__message"
          type="danger"
        >
          {responseErrorMessages || t(errorMessageKey)}
        </Typography.Text>
      ) : null}
      <Form
        key={countryId}
        layout="vertical"
        validateMessages={validateMessages}
        className="b2b-user-form"
        onFinish={onSubmit}
        form={form}
        initialValues={customInitialValues}
        {...props}
      >
        <Row>
          <Col span={24}>
            <Row gutter={24}>
              <Col span={12}>
                <CustomFormItem name="salutation">
                  <B2becCustomSelect
                    options={USER_SALUTATION_ARRAY.map((item) => ({
                      label: t(`form.label.${item.label}`),
                      value: item.value,
                    }))}
                  />
                </CustomFormItem>
              </Col>
              <Col span={12} />
              <Col span={12}>
                <CustomFormItem
                  name="firstName"
                  rules={[{ required: !isInvitationForm }]}
                >
                  <Input />
                </CustomFormItem>
              </Col>
              <Col span={12}>
                <CustomFormItem
                  name="lastName"
                  rules={[{ required: !isInvitationForm }]}
                >
                  <Input />
                </CustomFormItem>
              </Col>
              <Col span={24}>
                <CustomFormItem
                  name="contactEmail"
                  rules={[{ required: true }, { type: 'email' }]}
                >
                  <Input disabled={initialValues?.isInvitation === true} />
                </CustomFormItem>
              </Col>
              <Col span={isEditForm ? 24 : 12}>
                <CustomFormItem
                  name="contactNumber"
                  rules={[{ required: !isInvitationForm && !isEditForm }]}
                >
                  <Input />
                </CustomFormItem>
              </Col>
              <Col span={12}>
                <CustomFormItem
                  name="sapCustomerNumber"
                  rules={[{ required: true }]}
                >
                  <Input />
                </CustomFormItem>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="custom-form-item"
                  label={t('form.label.salesOrg')}
                  name="countryId"
                  rules={[
                    {
                      required: true,
                      message: t('form.validate.required', {
                        label: 'salesOrg',
                      }),
                    },
                  ]}
                >
                  <B2bSelectSalesOrg disabled={isEditForm} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <CustomFormItem
                  name="language"
                  rules={[{ required: isInvitationForm }]}
                >
                  <B2becCustomSelect
                    options={availableLanguages}
                    loading={isFetchingUserConfigurations}
                    disabled={isFetchingUserConfigurations}
                  />
                </CustomFormItem>
              </Col>
              {shouldShowAssignRolesSection && (
                <Col span={24} className="b2b-user-form__assign-roles">
                  <CustomFormItem
                    name="countryRoles"
                    label="assignRoles"
                    rules={[{ required: true }]}
                  >
                    <B2becAssignRole
                      assignableCountryRolesList={assignableCountryRolesList}
                    />
                  </CustomFormItem>
                </Col>
              )}
              <Col span={24}>
                <CustomFormItem name="userStatus" rules={[{ required: true }]}>
                  <UserStatusCheckbox isDisabled={isInvitationForm} />
                </CustomFormItem>
              </Col>
              <Col span={24}>
                <CustomFormItem name="sessionBasedShoppingCart">
                  <ShoppingCartType isDisabled />
                </CustomFormItem>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col>
            <Form.Item noStyle>
              <CustomButton
                type="ghost"
                onClick={onCancel}
                disabled={
                  status === ASYNC_STATUS.PENDING ||
                  changeEmailAddressStatus === ASYNC_STATUS.PENDING ||
                  changeCustomerSapStatus === ASYNC_STATUS.PENDING ||
                  handleAssignRolesStatus === ASYNC_STATUS.PENDING
                }
              >
                {cancelText}
              </CustomButton>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item noStyle>
              <CustomButton
                htmlType="submit"
                disabled={
                  status === ASYNC_STATUS.PENDING ||
                  changeEmailAddressStatus === ASYNC_STATUS.PENDING ||
                  changeCustomerSapStatus === ASYNC_STATUS.PENDING ||
                  handleAssignRolesStatus === ASYNC_STATUS.PENDING
                }
              >
                {submitText}
              </CustomButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

B2bUserForm.propTypes = {
  form: PropTypes.shape({}),
  initialValues: PropTypes.shape({
    contactEmail: PropTypes.string,
    sapCustomerNumber: PropTypes.string,
    isInvitation: PropTypes.bool,
    countryRoles: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  cancelText: PropTypes.node,
  submitText: PropTypes.node,
  errorMessageKey: PropTypes.string,
  successMessageKey: PropTypes.string,
  submitFunc: PropTypes.func.isRequired,
  changeEmailAddress: PropTypes.func,
  doAssignRoles: PropTypes.func,
  changeCustomerSap: PropTypes.func,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func,
  type: PropTypes.oneOf([USER_FORM_TYPES.INVITE, USER_FORM_TYPES.EDIT]),
  userId: PropTypes.string,
};

B2bUserForm.defaultProps = {
  form: undefined,
  initialValues: undefined,
  submitText: <Trans i18nKey="buttonTexts.inviteNewUser" />,
  cancelText: <Trans i18nKey="buttonTexts.cancel" />,
  errorMessageKey: 'userManagement.messages.createUserInvitationError',
  successMessageKey: 'userManagement.messages.createUserInvitationSuccess',
  onSuccess: undefined,
  onError: undefined,
  onCancel: undefined,
  type: USER_FORM_TYPES.INVITE,
  changeEmailAddress: () => {},
  doAssignRoles: () => {},
  changeCustomerSap: () => {},
  userId: '',
};

export const B2bUserInvitationForm = (props) => {
  const handleSubmit = (body) => {
    return adminFapiUserManagement.inviteNewUser({
      countryId: body?.country,
      customerNumber: body?.sapCustomerNumber,
      salutation: body?.title,
      ...body,
    });
  };

  return (
    <B2bUserForm
      {...props}
      type={USER_FORM_TYPES.INVITE}
      submitFunc={handleSubmit}
      errorMessageKey="userManagement.messages.editUserError"
      successMessageKey="userManagement.messages.createUserInvitationSuccess"
    />
  );
};

const formatValues = ({
  firstName,
  lastName,
  language,
  countryId,
  email: contactEmail,
  phoneNumber,
  userStatus,
  salutation,
  isInvitation,
  sapCustomerNumber,
  countryRoles,
  sessionBasedShoppingCart,
}) => {
  return {
    firstName,
    lastName,
    contactEmail,
    contactNumber: phoneNumber !== 'N/A' && phoneNumber ? phoneNumber : '',
    language,
    countryId,
    userStatus,
    salutation,
    isInvitation,
    sapCustomerNumber,
    countryRoles,
    sessionBasedShoppingCart,
  };
};

export const B2bUserEditForm = ({
  userId,
  initialValues,
  assignedRoles,
  isInvitation,
  ...props
}) => {
  const values = formatValues(initialValues);

  const handleSubmit = (body) => {
    if (isInvitation) {
      let modifiedBody = body;
      const {
        sapCustomerNumber: customerNumber,
        title: salutation,
        ...rest
      } = modifiedBody;
      modifiedBody = { customerNumber, salutation, ...rest };

      return adminFapiUserManagement.updateInvitation(userId)({
        ...modifiedBody,
      });
    }
    return adminFapiUserManagement.editUser(userId)({
      countryId: body?.country,
      ...body,
    });
  };

  const handleChangeEmailAddress = (requestBody) => {
    return adminFapiUserManagement.editUserEmailAddress(userId)({
      customerId: values.sapCustomerNumber,
      ...requestBody,
    });
  };

  const handleChangeCustomerSap = (requestBody) => {
    return adminFapiUserManagement.editCustomerSap(userId)({
      sapCustomerId: values.sapCustomerNumber,
      ...requestBody,
    });
  };

  const doAssignRoles = (requestBody) => {
    return fapiRoleService.assignRolesToUser(requestBody);
  };

  return (
    <B2bUserForm
      {...props}
      type={USER_FORM_TYPES.EDIT}
      submitFunc={handleSubmit}
      submitText={<Trans i18nKey="buttonTexts.saveChanges" />}
      errorMessageKey="userManagement.messages.editUserError"
      successMessageKey="userManagement.messages.editUserSuccess"
      initialValues={values}
      changeEmailAddress={handleChangeEmailAddress}
      changeCustomerSap={handleChangeCustomerSap}
      doAssignRoles={doAssignRoles}
      assignedRoles={assignedRoles}
      userId={userId}
      isInvitation={isInvitation}
    />
  );
};

B2bUserEditForm.propTypes = {
  userId: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

B2bUserEditForm.defaultProps = {};
