import { createSelector } from '@reduxjs/toolkit';
import { COUNTRY_BY_SALESORG } from 'libs/constants';
import { DEFAULT_LANGUAGES } from 'libs/constants/lang';
import { PUNCHOUT_TYPES } from 'libs/constants/punchoutTypes';
import { isValidArray } from 'libs/utils/array';
import { cleanLanguage, getLanguage } from 'libs/utils/language';

export const selectCurrentUserDetails = (state) => state.user.userDetails;
export const selectCurrentUserConfigurations = (state) =>
  state.user.userConfigurations;

export const selectServicePackagePage = (state) =>
  state.user.userConfigurations?.servicePackagePage ||
  state.config.servicePackagePage;

export const selectNumberSeparator = (state) =>
  state.user.userConfigurations?.numberSeparator ||
  state.config.numberSeparator ||
  'en-GB';

export const selectIsFetchingUserDetails = (state) =>
  state.user.isFetchingUserDetails;

export const selectIsFetchingUserConfigurations = (state) =>
  state.user.isFetchingUserConfigurations;

export const selectIsChangingLanguage = (state) =>
  state.user.isChangingLanguage;

export const selectUsername = (state) =>
  state.user.userDetails?.userInfo?.displayName;

export const selectUserLanguage = createSelector(
  (state) =>
    state.user.userDetails?.language ||
    state.user.userDetails?.userInfo?.language,
  cleanLanguage
);

export const selectUserCurrency = (state) =>
  state.user.userConfigurations?.currency;

export const selectShowSparePartLink = (state) =>
  state.user.userConfigurations?.showSparePartLink ||
  state.config.showSparePartLink;

export const selectUserCountryId = (state) =>
  state.user.userDetails?.countryId ||
  state.user.userDetails?.userInfo?.countryId;

export const selectUserCountry = createSelector(
  (state) => {
    return (
      state.user.userDetails?.countryId ||
      state.user.userDetails?.userInfo?.countryId
    );
  },
  (countryId) => COUNTRY_BY_SALESORG[countryId]
);

export const selectUserCulture = (state) =>
  state.user.userDetails?.userInfo?.language;

export const selectInitialLangCode = createSelector(
  selectUserLanguage,
  (userLanguage) =>
    getLanguage(userLanguage || localStorage.getItem('lng')) ||
    DEFAULT_LANGUAGES[0].value
);

export const selectServicePackagePageByLanguage = createSelector(
  selectServicePackagePage,
  selectUserLanguage,
  (servicePackagePage, language) => {
    try {
      return JSON.parse(servicePackagePage)[language];
    } catch (e) {
      return null;
    }
  }
);

const selectableLanguages = (state) =>
  state.config.availableLanguages.data ||
  state.user.userConfigurations?.availableLanguages;

export const selectAvailableLanguages = createSelector(
  selectableLanguages,
  (availableLanguages) =>
    isValidArray(availableLanguages)
      ? availableLanguages.map((lang) => ({
          label: lang?.name,
          value: lang?.value,
        }))
      : DEFAULT_LANGUAGES
);

const selectUserStatus = (state) =>
  state.user.userDetails?.userInfo?.status || state.user.userDetails?.status;

export const selectUserFirstName = (state) =>
  state.user.userDetails?.firstName ||
  state.user.userDetails?.userInfo?.firstName;

export const selectUserLastName = (state) =>
  state.user.userDetails?.lastName ||
  state.user.userDetails?.userInfo?.lastName;

const selectUserSalutation = (state) =>
  state.user.userDetails?.salutation || state.user.userDetails?.userInfo?.title;

export const selectHasSessionBasedShoppingCart = (state) =>
  state.user.userDetails?.sessionBasedShoppingCart ||
  state.user.userDetails?.userInfo?.sessionBasedShoppingCart;

export const selectCustomerNumberForTechnicalUser = (state) =>
  state.user.userDetails?.customerNumber;

export const selectUserStatusLowerCase = createSelector(
  selectUserStatus,
  (userStatus) => userStatus?.toLowerCase()
);

export const selectUserSalutationLowerCase = createSelector(
  selectUserSalutation,
  (userSalutation) => userSalutation?.toLowerCase()
);

export const selectCustomerNumber = (state) => state.user.customerNumber;
export const selectCustomerCountryId = (state) => state.user.countryId;
export const selectCustomerUserId = (state) => state.user.userId;

// todo: will replace with the real flag when working in technical user creation ticket
export const selectIsProcurementTechnicalUser = (state) =>
  state.user.userDetails?.punchOutType === PUNCHOUT_TYPES.CXML;
