/* eslint-disable jsx-a11y/no-static-element-interactions */
import './styles.scss';

import { Card, Checkbox, Col, Row, Tooltip } from 'antd';
import { ReactComponent as WarningIcon } from 'assets/icons/alerticon.svg';
import clsx from 'clsx';
import B2becTranslation from 'components/B2becTranslation';
import B2BecLink from 'components/B2BLink';
import FormatPrice from 'components/FormatPrice';
import { HeartIcon } from 'components/Icons';
import useAsync from 'hooks/useAsync';
import usePermission from 'hooks/usePermission';
import {
  ASYNC_STATUS,
  BUTTON_TYPE,
  INVALID_TYPES,
  SERVICE_ENTRY,
} from 'libs/constants';
import {
  MODULE_SERVICE_RIGHTS,
  MODULE_SHOP_NAVIGATION_RIGHTS,
  MODULE_WISHLIST_RIGHTS,
} from 'libs/constants/modulerights';
import { formatSerialNumber } from 'libs/utils/formatSerialNumber';
import { linkGenerator } from 'libs/utils/language';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { serviceCaseService } from 'services';
import { selectServiceRepairPageByLanguage } from 'store/selectors/configSelector';
import { selectUserCountryId } from 'store/selectors/userSelector';
import { setSelectedMachineInfo } from 'store/slices/serviceCaseSlice';

import PermissionWrapper from '../../HOCs/permissionWrapper';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import { formatDate, getFormatType } from '../../libs/utils/formatDate';
import B2becTooltip from '../B2becTooltip';
import CustomButton from '../CustomButton';
import ImageWithFallBack from '../ImageWithFallback';

const validateExcludedMachine = (materialNumber, countryId) => {
  return serviceCaseService.validateMaterialNumber(materialNumber, countryId);
};

// add forwardRef to be able to get the ref variable from parent component
const ProductCard = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isLargeScreen } = useDeviceDetect();
  const dispatch = useDispatch();
  const {
    verifiedPermissions: [
      canCreateRepairService,
      canCreateMaintenanceService,
      canAddProductToCart,
      canCompareProduct,
    ],
  } = usePermission([
    MODULE_SERVICE_RIGHTS.CREATE_REPAIR_SERVICE_CASE,
    MODULE_SERVICE_RIGHTS.CREATE_MAINTENANCE_SERVICE_CASE,
    MODULE_SHOP_NAVIGATION_RIGHTS.ADD_PRODUCT_TO_SHOPPING_CART,
    MODULE_SHOP_NAVIGATION_RIGHTS.PRODUCT_COMPARISION,
  ]);

  const isMountedRef = useRef(null);

  const countryId = useSelector(selectUserCountryId);

  const {
    execute: executeExcludedMachineValidation,
    status: excludedMachineValidationStatus,
  } = useAsync(validateExcludedMachine, false);

  const [isExcludedMachine, setIsExcludedMachine] = useState(false);
  const [isShowWarningMessages, setIsShowWarningMessages] = useState(false);

  const {
    className,
    style,
    date,
    name,
    category,
    img,
    imageUrl,
    materialNumber,
    materialId,
    listPrice,
    addToCartBtn,
    comparingProductIds,
    onCheckHandler,
    isSuitableProduct,
    isCompareCheckboxDisplayed,
    // 'isMachineSelection' is used for recognizing machine list in service case
    isMachineSelection,
    isRenderedInSupportArea,
    selectMachineHandler,
    serialNumber,
    salesOrderNumber,
    requiresInstruction: isBProgramProduct,
    currencyUnit,
    setVerifyStatus,
    displayAddToCart: shouldDisplayAddToCart,
    shouldDisplayListPrice,
    onAddToWishlist,
  } = props;

  // To check list price is "0.00"
  const isPriceEqualZero = +listPrice === 0 || listPrice === '';

  const shouldHideAddToCartButton = Boolean(
    !shouldDisplayAddToCart ||
    isBProgramProduct ||
    isPriceEqualZero ||
    !canAddProductToCart
  );

  const isComparingProduct = (comparingProductIds || []).includes(
    materialNumber
  );

  const renderHoverLayer = () => (
    <div className="hover-layer__wrapper">
      <Row className="functions__wrapper fadeIn-1">
        {isCompareCheckboxDisplayed && (
          <>
            <Col>
              {canCompareProduct && (
                <Checkbox
                  className="kaercher-checkbox"
                  checked={isComparingProduct}
                  onChange={(e) =>
                    onCheckHandler(
                      {
                        materialNumber,
                        materialName: name,
                        categoryName: category,
                        listPrice,
                        currencyUnit,
                        pictureUrl: img || imageUrl,
                        displayListPrice: shouldDisplayListPrice,
                      },
                      e
                    )
                  }
                >
                  {t('productComparison.button')}
                </Checkbox>
              )}
            </Col>
            <Col style={{ textAlign: 'right' }}>
              {shouldHideAddToCartButton ? null : addToCartBtn}
            </Col>
          </>
        )}
        {isSuitableProduct && (
          <div style={{ marginLeft: 'auto' }}>
            {shouldHideAddToCartButton ? null : addToCartBtn}
          </div>
        )}
      </Row>
    </div>
  );

  const productCardHasPrice = () => {
    return (
      <div
        className={`product-card-modified card-item__container--price card-item__container--price--hover-border ${className}`}
        style={style}
      >
        <PermissionWrapper
          permission={MODULE_WISHLIST_RIGHTS.ADD_PRODUCT_CHANGE_WISHLIST}
        >
          <div className="add-to-wishlist">
            <button
              type="button"
              className="button-as-link"
              onClick={() => {
                onAddToWishlist({
                  materialName: name,
                  materialNumber: materialNumber || materialId,
                  img: imageUrl || img,
                });
              }}
            >
              <HeartIcon className="add-to-wishlist__button" />
            </button>
          </div>
        </PermissionWrapper>

        <div>
          <B2BecLink
            className="ant-card-cover fadeIn-2"
            to={`/product/${materialNumber || materialId}`}
          >
            <ImageWithFallBack alt={name} src={img || imageUrl} />
          </B2BecLink>
          <B2BecLink
            className="link-normalize"
            to={`/product/${materialNumber || materialId}`}
          >
            <Tooltip title={name}>
              <p className="card-item__title fadeIn-05">{name}</p>
            </Tooltip>
          </B2BecLink>
          <B2BecLink
            className="link-normalize card-item__item-number fadeIn-08"
            to={`/product/${materialNumber || materialId}`}
          >
            <p>
              {`${t('productCard.itemNumber')} `}
              {materialNumber || materialId}
            </p>
          </B2BecLink>
          <Row justify="space-between" className="list-price__wrapper fadeIn-1">
            <Col className="list-price__wrapper__title">
              {t('productCard.listPrice')}
            </Col>
            <Col className="list-price__wrapper__price">
              {isPriceEqualZero || !shouldDisplayListPrice ? (
                t('productCard.onRequest')
              ) : (
                <FormatPrice price={listPrice} currencyUnit={currencyUnit} />
              )}
            </Col>
          </Row>
        </div>
        {isCompareCheckboxDisplayed || isSuitableProduct
          ? renderHoverLayer()
          : null}
      </div>
    );
  };

  const mobileProductCardHasPrice = () => (
    <Card
      className={`product-card__content-container ${className}`}
      style={style}
    >
      <div className="add-to-wishlist">
        <button
          type="button"
          className="button-as-link"
          onClick={() => {
            onAddToWishlist({
              materialName: name,
              materialNumber: materialNumber || materialId,
              img,
            });
          }}
        >
          <HeartIcon className="add-to-wishlist__icon" />
        </button>
      </div>
      <div style={{ display: 'flex', height: 'inherit' }}>
        <B2BecLink
          className="fadeIn-2"
          to={`/product/${materialNumber || materialId}`}
        >
          <ImageWithFallBack
            alt={name}
            src={img}
            className="product-card__content-image"
          />
        </B2BecLink>

        <div className="product-card__content--right-wrapper">
          <B2BecLink
            style={{ marginBottom: 13.3 }}
            className="link-normalize fadeIn-08"
            to={`/product/${materialNumber || materialId}`}
          >
            <p className="fadeIn-05 fontBold product-card__content-name">
              {name}
            </p>

            <p className="fadeIn-05 product-card__content-material-number">
              {`${t('productCard.itemNumber')} `}
              {materialNumber || materialId}
            </p>
          </B2BecLink>

          <div className="product-card__content--lower-wrapper">
            <div className="fadeIn-1 product-card__list-price-wrapper">
              <span className="product-card__list-price-text">
                {t('productCard.listPrice')}
              </span>
              <span className="fontBold product-card__list-price-number">
                {isPriceEqualZero ? (
                  t('productCard.onRequest')
                ) : (
                  <FormatPrice price={listPrice} currencyUnit={currencyUnit} />
                )}
              </span>
            </div>
            {shouldHideAddToCartButton ? null : addToCartBtn}
          </div>
        </div>
      </div>
    </Card>
  );

  // validate excluded machine before changing page
  const onValidateExcludedMachineHandler = useCallback(
    (_materialNumber, countryId) => {
      isMountedRef.current = true;
      executeExcludedMachineValidation(_materialNumber, countryId).then(
        ({ response, error }) => {
          if (isMountedRef.current && response) {
            setIsExcludedMachine(false);
            if (isMachineSelection) {
              selectMachineHandler(_materialNumber);
            } else {
              dispatch(
                setSelectedMachineInfo({
                  id: _materialNumber,
                  serialNumber: formatSerialNumber(serialNumber),
                  isExcludedMachine: false,
                  validateStatus: ASYNC_STATUS.SUCCESS,
                  serviceEntry: SERVICE_ENTRY.MACHINE,
                  faqList: [],
                })
              );

              history.push(linkGenerator('/request-services'));
            }
          }
          if (error?.response?.data === INVALID_TYPES.EXCLUDED) {
            setIsExcludedMachine(true);
          }

          setVerifyStatus(false);
        }
      );
      return () => {
        isMountedRef.current = false;
      };
    },
    [
      history,
      dispatch,
      serialNumber,
      isMachineSelection,
      selectMachineHandler,
      setVerifyStatus,
      executeExcludedMachineValidation,
    ]
  );

  const renderExcludedMachineMessagesBlock = useCallback(() => {
    return (
      isExcludedMachine &&
      isShowWarningMessages && (
        <div
          className={clsx('card-item__excluded-massages-wrapper', {
            'card-item__excluded-massages-wrapper--mobile':
              !isLargeScreen && isMachineSelection,
          })}
        >
          <B2becTranslation
            value="service.selectMachine.excludedMachineMessages"
            isComponent
            htmlTags={[<div />, <LinkToExternalPage />]}
          />
        </div>
      )
    );
  }, [
    isExcludedMachine,
    isShowWarningMessages,
    isLargeScreen,
    isMachineSelection,
  ]);

  const renderExcludedWarning = useCallback(() => {
    const onToggleMessageHandler = () => {
      setIsShowWarningMessages((prevState) => !prevState);
      // get current product card top position
      const elementId = `${materialNumber || materialId}-${salesOrderNumber}`;
      const element = ref.current.find((el) => el.id === elementId);
      const yTop =
        element.getBoundingClientRect().top + window.pageYOffset - 200;
      // scroll to that position to be able to see the excluded messages
      window.scrollTo({ top: yTop, behavior: 'smooth' });
    };

    return (
      isExcludedMachine && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          className={clsx('card-item__btn-wrapper__icon__wrapper')}
          onClick={onToggleMessageHandler}
        >
          <WarningIcon />
        </div>
      )
    );
  }, [isExcludedMachine, materialId, materialNumber, ref, salesOrderNumber]);

  const renderButtons = () => {
    const shouldDisableAvailableServiceButton =
      (!canCreateRepairService && !canCreateMaintenanceService) ||
      excludedMachineValidationStatus === ASYNC_STATUS.PENDING;

    return (
      <div
        className="card-item__btn-wrapper fadeIn-2"
        style={{ display: isMachineSelection && 'flex' }}
      >
        {isMachineSelection ? (
          <CustomButton
            style={{ width: '100%' }}
            disabled={excludedMachineValidationStatus === ASYNC_STATUS.PENDING}
            onClick={() =>
              onValidateExcludedMachineHandler(
                materialNumber || materialId,
                countryId
              )
            }
          >
            {excludedMachineValidationStatus === ASYNC_STATUS.PENDING
              ? t('service.selectMachine.verifyingMachine')
              : t('service.selectMachine.button')}
          </CustomButton>
        ) : (
          <>
            <CustomButton
              onClick={() =>
                history.push(
                  linkGenerator(`/product/${materialNumber || materialId}/all`)
                )
              }
            >
              {t('myMachines.matchingProduct')}
            </CustomButton>
            <CustomButton
              type={BUTTON_TYPE.ACTION}
              disabled={shouldDisableAvailableServiceButton}
              onClick={() =>
                onValidateExcludedMachineHandler(
                  materialNumber || materialId,
                  countryId
                )
              }
            >
              {excludedMachineValidationStatus === ASYNC_STATUS.PENDING
                ? t('service.selectMachine.verifyingMachine')
                : t('service.selectMachine.availableService')}
            </CustomButton>
          </>
        )}
      </div>
    );
  };

  const renderProductCardWithoutPrice = () => {
    return (
      <Card
        className={`card-item__container--none-price ${className}`}
        style={style}
      >
        <span className="card-item__date">
          {formatDate(date, getFormatType().default)}
        </span>
        {renderExcludedMachineMessagesBlock()}
        <B2BecLink
          className="ant-card-cover fadeIn-2"
          to={`/product/${materialNumber || materialId}`}
        >
          <ImageWithFallBack alt={name} src={img} />
        </B2BecLink>
        <h3 className="category__name fadeIn-05">{category}</h3>
        <h4 className="card-item__title fadeIn-08">
          <B2BecLink
            className="link-normalize"
            to={`/product/${materialNumber || materialId}`}
          >
            <B2becTooltip title={name} content={name} />
          </B2BecLink>
        </h4>
        <div className="card-item__material-number--wrapper">
          <div className="card-item__item-number--wrapper">
            <B2BecLink
              className="link-normalize fadeIn-1"
              to={`/product/${materialNumber || materialId}`}
            >
              <p>
                {`${t('productCard.itemNumber')} `}
                {materialNumber || materialId}
              </p>
            </B2BecLink>
            {renderExcludedWarning()}
          </div>
          <p>
            {serialNumber && (
              <>
                {`${t('productCard.serialNumber')} `}
                {formatSerialNumber(serialNumber)}
              </>
            )}
          </p>
        </div>
        {!isRenderedInSupportArea && renderButtons()}
      </Card>
    );
  };

  const mobileServiceProductCard = useCallback(() => {
    const onValidateMachine = () => {
      if (excludedMachineValidationStatus !== ASYNC_STATUS.PENDING) {
        // set verifying status before starting validation
        // prevent validating multiple items and then process the next step with the unwanted item
        setVerifyStatus(true);
        onValidateExcludedMachineHandler(
          materialNumber || materialId,
          countryId
        );
      }
    };

    return (
      <>
        {renderExcludedWarning()}
        {renderExcludedMachineMessagesBlock()}
        <Card
          className={`product-card__content-container ${className}`}
          style={style}
          onClick={onValidateMachine}
        >
          <div style={{ display: 'flex', height: 'inherit' }}>
            <div>
              <ImageWithFallBack
                alt={name}
                src={img}
                className="product-card__content-image"
              />
            </div>

            <div className="product-card__content--right-wrapper">
              <span className="product-card__content-date">
                {formatDate(date, getFormatType().default)}
              </span>
              <div>
                <p className="fadeIn-05 product-card__content-category">
                  {category}
                </p>
                <p className="fadeIn-05 fontBold product-card__content-name--service-case">
                  {name}
                </p>

                <p className="fadeIn-05 product-card__content-material-number">
                  {`${t('productCard.itemNumber')} `}
                  {materialNumber || materialId}
                </p>
                {serialNumber && (
                  <p className="fadeIn-05 product-card__content-serial-number">
                    {`${t('productCard.serialNumber')} `}
                    {serialNumber}
                  </p>
                )}
              </div>

              <div className="product-card__content--lower-wrapper">
                {shouldHideAddToCartButton ? null : addToCartBtn}
              </div>
            </div>
          </div>
        </Card>
      </>
    );
  }, [
    t,
    img,
    date,
    name,
    style,
    category,
    className,
    countryId,
    addToCartBtn,
    materialId,
    serialNumber,
    materialNumber,
    renderExcludedWarning,
    setVerifyStatus,
    shouldHideAddToCartButton,
    excludedMachineValidationStatus,
    onValidateExcludedMachineHandler,
    renderExcludedMachineMessagesBlock,
  ]);

  const renderProductCard = () =>
    isLargeScreen ? productCardHasPrice() : mobileProductCardHasPrice();

  const renderServiceProductCard = () =>
    isLargeScreen
      ? renderProductCardWithoutPrice()
      : mobileServiceProductCard();

  if (typeof addToCartBtn === 'object') {
    return renderProductCard();
  }
  return isMachineSelection
    ? renderServiceProductCard()
    : renderProductCardWithoutPrice();
});

ProductCard.propTypes = {
  date: PropTypes.string,
  name: PropTypes.string,
  img: PropTypes.string,
  imageUrl: PropTypes.string,
  category: PropTypes.string,
  materialNumber: PropTypes.string,
  materialId: PropTypes.string,
  listPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  style: PropTypes.shape({}),
  addToCartBtn: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onCheckHandler: PropTypes.func,
  isSuitableProduct: PropTypes.bool,
  isCompareCheckboxDisplayed: PropTypes.bool,
  isMachineSelection: PropTypes.bool,
  isRenderedInSupportArea: PropTypes.bool,
  selectMachineHandler: PropTypes.func,
  serialNumber: PropTypes.string,
  requiresInstruction: PropTypes.bool,
  comparingProductIds: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({}),
  ]),
  salesOrderNumber: PropTypes.string,
  currencyUnit: PropTypes.string,
  setVerifyStatus: PropTypes.func,
  displayAddToCart: PropTypes.bool,
  shouldDisplayListPrice: PropTypes.bool,
};

ProductCard.defaultProps = {
  date: '',
  name: '',
  img: '',
  imageUrl: '',
  category: '',
  materialNumber: '',
  materialId: '',
  listPrice: 0 || '',
  className: '',
  style: {},
  addToCartBtn: '',
  onCheckHandler: () => { },
  isSuitableProduct: false,
  isCompareCheckboxDisplayed: false,
  isMachineSelection: false,
  isRenderedInSupportArea: false,
  selectMachineHandler: () => { },
  serialNumber: '',
  requiresInstruction: false,
  comparingProductIds: [],
  salesOrderNumber: '',
  currencyUnit: '',
  setVerifyStatus: () => { },
  displayAddToCart: true,
  shouldDisplayListPrice: true,
};

const LinkToExternalPage = ({ children }) => {
  const serviceRepairPage = useSelector(selectServiceRepairPageByLanguage);

  return (
    <a
      className="select-machine__excluded-machine__redirect-link"
      href={serviceRepairPage}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};

LinkToExternalPage.propTypes = {
  children: PropTypes.arrayOf(PropTypes.string),
};

LinkToExternalPage.defaultProps = {
  children: [],
};

export default ProductCard;
